<template>
  <div
    class="row mt-4"
  >
    <div
      class="col-8"
    >
      <dropdown
        label="Groep toevoegen"
        :display-last-selected-option="false"
        :options="options"
        placeholder-label="Selecteer een groep"
        @update:value="addGroup"
      />
    </div>
  </div>
  <div
    class="grid grid2"
  >
    <div
      v-for="group in currentGroups"
      :key="group.id" 
      class="grid-item d-flex align-items-center justify-content-between"
    >
      <h6
        class="m-0"
      >
        <router-link
          :to="`/groups/${group.id}`"
        >
          {{ group.title }}
        </router-link>
      </h6>
      <button
        class="btn btn-primary btn-sm float-end d-inline mb-0"
        :style="{ width: '50px' }"
        @click.prevent="() => removeGroup(group.id)"
      >
        <i
          class="top-0 start-0 bottom-0 end-0 d-flex justify-content-center
                  align-items-center material-icons text-light fs-4"
        >
          delete_outline
        </i>
      </button>
    </div>
  </div>
  <div
    class="row mt-4"
  >
    <div
      class="col-12"
    >
      <material-button
        v-if="showUpdate"
        class="float-end mt-6 mb-0"
        color="dark" variant="gradient"
        size="sm"
      >
        Update gegevens
      </material-button>
    </div>
  </div>
</template>

<script>
import Dropdown from '@/components/UI/Dropdown.vue';
import MaterialButton from '@/components/MaterialButton.vue';
import UserGroupStore from '@/store/pages/AppUserEdit/user-groups'

import { getAllGroups } from '@/api/providers/groups';
import { createYesNoSweetAlert } from '@/helpers/sweetalert';

import { editGroup } from '@/api/providers/groups';

export default {
  components: { Dropdown,
    MaterialButton,
  },
  props: {
    showUpdate: {
      type: Boolean,
      required: true,
    }
  },

  data() {
    return {
      groups: []
    }
  },

  computed: {
    currentGroups() {
      return UserGroupStore.getters.currentGroups;
    },
    options() {
      let currentGroupIds = this.currentGroups.map(g => g.id);
      return this.groups
        .filter(g => !currentGroupIds.includes(g.id))
        .map(g => { return { label: g.title, value: g.id } });
    }
  },

  async created() {
    this.groups = await getAllGroups();
  },

  methods: {
    addGroup(groupId) {
      let groupIndex = this.groups.findIndex(g => g.id === groupId);

      if (groupIndex < 0) return;

      UserGroupStore.dispatch('addGroup', this.groups[groupIndex]);
    },

    removeGroup(groupId) {
      let groupIndex = this.groups.findIndex(g => g.id === groupId);

      if (groupIndex < 0) return;

      createYesNoSweetAlert(
        `Weet u zeker dat u deze gebruiker wilt onkoppelen van ${this.groups[groupIndex].title}?`,
        () => UserGroupStore.dispatch('removeGroup', this.groups[groupIndex])
      );
    },

    async submit(userId) {
			let newGroupsSuccess = await this.submitNewGroups(userId);
			let removedGroupsSuccess = await this.submitRemovedGroups(userId);
			
			if (newGroupsSuccess && removedGroupsSuccess) return;

      throw Error(
        "Niet alle wijzigingen aan de gebruikers-groepen konden correct worden verwerkt"
      );
		},

    async submitNewGroups(userId) {
      try {
        for (let group of UserGroupStore.getters.addedGroups) {
          await editGroup(
            group.id,
            group.title,
            [ userId, ],
            [],
          );
        }
        return true;
      }
      catch {
        return false;
      }
		},
		async submitRemovedGroups(userId) {
      try {
        for (let group of UserGroupStore.getters.removedGroups) {
          await editGroup(
            group.id,
            group.title,
            [],
            [ userId, ],
          );
        }
        return true;
      }
      catch {
        return false;
      }
		}
  }
}
</script>