import { createStore } from 'vuex';

const GroupStatus = {
	Added: 'Added',
	None: 'None',
	Removed: 'Removed'
}

const store = createStore({
	state: {
		groups: [],
	},

	getters: {
		groups: (state) => state.groups,
		currentGroups: (state) => state.groups.filter(
			g => g.groupStatus !== GroupStatus.Removed
		),
		addedGroups: (state) => state.groups.filter(
			g => g.isNew && g.groupStatus == GroupStatus.Added
			
		),
		removedGroups: (state) => state.groups.filter(
			g => !g.isNew && g.groupStatus == GroupStatus.Removed
		),
	},

	mutations: {
		initializeGroups: (state, payload) => state.groups = payload.map(
			g => { return { ...g, groupStatus: GroupStatus.None } }
		),
		addGroup(state, payload) {
			let index = state.groups.findIndex(g => payload.id === g.id);
			if (index < 0) {
				state.groups.push({
					...payload,
					groupStatus: GroupStatus.Added,
					isNew: true,
				});
				return;
			}
			state.groups[index].groupStatus = GroupStatus.Added;
		},
		removeGroup: (state, payload) => {
			let index = state.groups.findIndex(g => g.id == payload.id);
			
			if (index < 0) {
				return;
			}

			state.groups[index].groupStatus = GroupStatus.Removed;
		},
	},
	
	actions: {
		initializeGroups: ({ commit }, groups) => commit('initializeGroups', groups),
		addGroup: ({ commit }, group) => commit('addGroup', group),
		removeGroup: ({ commit }, group) => commit('removeGroup', group),
	},
});

export default store;